import React from "react";
import {
  BaseForm,
  Input,
  Upload,
  Searchable,
  VALIDATION_TYPES,
  Textarea,
  Radio,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import "./style.css";
import { Helmet } from "react-helmet";
import { get } from "../../../../lib/storage";

const SOCIAL_MEDIA = [
  {
    text: "Snapchat",
    value: "snapchat",
  },
  {
    text: "Tiktok",
    value: "tiktok",
  },
  {
    text: "Instagram",
    value: "instagram",
  },
  {
    text: "Youtube",
    value: "youtube",
  },
  {
    text: "Facebook",
    value: "facebook",
  },

  {
    text: "Twitter",
    value: "twitter",
  },
  {
    text: "Pinterest",
    value: "pinterest",
  },
  {
    text: "Whatsapp",
    value: "whatsapp",
  },
];

export default class InfluencerForm extends BaseForm {
  constructor(props) {
    super(props);
    this.direction = get("dataLang") === "ar" ? "rtl" : "ltr";
  }

  render() {
    const { CancelButton, SubmitButton } = this.buttons;
    const { Form } = this.components;

    return (
      <div>
        <Helmet title="Zopping - Add Influencer" />
        <Form className="influencer-form">
          <Input
            label={getMessage("marketing.influencer.form.title.heading")}
            placeholder={getMessage(
              "marketing.influencer.form.title.placeholder"
            )}
            name="name"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage(
                "marketing.influencer.form.title.requiredMessage"
              ),
            }}
            dir={this.direction}
          />
          <div className="form-section">
            <Input
              label={getMessage(
                "marketing.influencer.form.snapchat.title.heading"
              )}
              placeholder={getMessage(
                "marketing.influencer.form.snapchat.title.placeholder"
              )}
              name="snapchat"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["metaData", "snapchat"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              dir={this.direction}
            />
            <Input
              label={getMessage(
                "marketing.influencer.form.tiktok.title.heading"
              )}
              placeholder={getMessage(
                "marketing.influencer.form.tiktok.title.placeholder"
              )}
              name="tiktok"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["metaData", "tiktok"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              dir={this.direction}
            />
            <Input
              label={getMessage(
                "marketing.influencer.form.instagram.title.heading"
              )}
              placeholder={getMessage(
                "marketing.influencer.form.instagram.title.placeholder"
              )}
              name="instagram"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["metaData", "instagram"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              dir={this.direction}
            />
            <Input
              label={getMessage(
                "marketing.influencer.form.youtube.title.heading"
              )}
              placeholder={getMessage(
                "marketing.influencer.form.youtube.title.placeholder"
              )}
              name="youtube"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["metaData", "youtube"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              dir={this.direction}
            />
            <Input
              label={getMessage(
                "marketing.influencer.form.facebook.title.heading"
              )}
              placeholder={getMessage(
                "marketing.influencer.form.facebook.title.placeholder"
              )}
              name="facebook"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["metaData", "facebook"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              dir={this.direction}
            />

            <Input
              label={getMessage(
                "marketing.influencer.form.twitter.title.heading"
              )}
              placeholder={getMessage(
                "marketing.influencer.form.twitter.title.placeholder"
              )}
              name="twitter"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["metaData", "twitter"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              dir={this.direction}
            />

            <Input
              label={getMessage(
                "marketing.influencer.form.pinterest.title.heading"
              )}
              placeholder={getMessage(
                "marketing.influencer.form.pinterest.title.placeholder"
              )}
              name="pinterest"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["metaData", "pinterest"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              dir={this.direction}
            />
            <Input
              label={getMessage(
                "marketing.influencer.form.whatsapp.title.heading"
              )}
              placeholder={getMessage(
                "marketing.influencer.form.whatsapp.title.placeholder"
              )}
              name="whatsapp"
              type="text"
              {...this.generateStateMappers({
                stateKeys: ["metaData", "whatsapp"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
              dir={this.direction}
            />
          </div>
          <Radio
            label={getMessage("marketing.influencer.form.default")}
            name="social media type"
            options={SOCIAL_MEDIA}
            {...this.generateStateMappers({
              stateKeys: ["metaData", "default"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Textarea
            label={getMessage(
              "marketing.influencer.form.description.title.heading"
            )}
            placeholder={getMessage(
              "marketing.influencer.form.description.title.placeholder"
            )}
            name="description"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["description"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            dir={this.direction}
          />
          <Upload
            label={getMessage(
              "marketing.recipe.form.featured.image.title.heading"
            )}
            placeholder={getMessage("brand.form.image.placeholder")}
            {...this.generateStateMappers({
              stateKeys: ["image"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Searchable
            label={getMessage("marketing.influencer.form.recipe.title.heading")}
            placeholder={getMessage(
              "marketing.influencer.form.recipe.add.placeholder"
            )}
            name="Recipe"
            searchUrl="/recipe-service/recipe"
            valueKey="id"
            responseKey="tag"
            nameKey="name"
            searchKey="name"
            dir={this.direction}
            transformResponse={(response) => response.data.recipes}
            transformRequest={(searchText, paramsDefault) => {
              paramsDefault["name"] = `%${paramsDefault.name}%`;
              return paramsDefault;
            }}
            multiple
            {...this.generateStateMappers({
              stateKeys: ["recipes"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <div className="form-buttons">
            <SubmitButton>{getMessage("save.text")}</SubmitButton>
            <CancelButton>{getMessage("cancel.text")}</CancelButton>
          </div>
        </Form>
      </div>
    );
  }
}
